*,
::after,
::before {
  box-sizing: border-box;
}
html, body, #root  {
  height: 100vh;
}
/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600&family=Montserrat&display=swap');

html {
  font-size: 100%;
} /*16px*/

:root {
  /* colors */
  --primary-100: #e2e0ff;
  --primary-200: #c1beff;
  --primary-300: #a29dff;
  --primary-400: #837dff;
  --primary-500: #645cff;
  --primary-600: #504acc;
  --primary-700: #3c3799;
  --primary-800: #282566;
  --primary-900: #141233;

  /* grey */
  --grey-50: #f8fafc;
  --grey-100: #f1f5f9;
  --grey-200: #e2e8f0;
  --grey-300: #cbd5e1;
  --grey-400: #94a3b8;
  --grey-500: #64748b;
  --grey-600: #475569;
  --grey-700: #334155;
  --grey-800: #1e293b;
  --grey-900: #0f172a;
  /* rest of the colors */
  --black: #222;
  --white: #fff;
  --red-light: #f8d7da;
  --red-dark: #842029;
  --green-light: #d1e7dd;
  --green-dark: #0f5132;

  /* fonts  */
  --headingFont: 'Roboto', sans-serif;
  --bodyFont: 'Nunito', sans-serif;
  --smallText: 0.7em;
  --small-text: 0.875rem;
  /* rest of the vars */
  --backgroundColor: var(--grey-50);
  --textColor: var(--grey-900);
  --borderRadius: 0.25rem;
  --letterSpacing: 1px;
  --transition: 0.3s ease-in-out all;
  --max-width: 1120px;
  --fixed-width: 500px;
  --fluid-width: 90vw;

  /* box shadow*/
  --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

body {
  background: var(--backgroundColor);
  font-family: var(--bodyFont);
  font-weight: 400;
  line-height: 1.75;
  color: var(--textColor);
}

p {
  margin-bottom: 1.5rem;
  max-width: 40em;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  margin-bottom: 1.38rem;
  font-family: var(--headingFont);
  font-weight: 400;
  line-height: 1.3;
  text-transform: capitalize;
  letter-spacing: var(--letterSpacing);
}

h1 {
  margin-top: 0;
  font-size: 3.052rem;
}

h2 {
  font-size: 2.441rem;
}

h3 {
  font-size: 1.953rem;
}

h4 {
  font-size: 1.563rem;
}

h5 {
  font-size: 1.25rem;
}

small,
.text-small {
  font-size: var(--smallText);
}

a {
  text-decoration: none;
}
ul {
  list-style-type: none;
  padding: 0;
}

.img {
  width: 100%;
  display: block;
  object-fit: cover;
}
/* buttons */

.btn {
  cursor: pointer;
  color: var(--white);
  background: var(--primary-500);
  border: transparent;
  border-radius: var(--borderRadius);
  letter-spacing: var(--letterSpacing);
  padding: 0.375rem 0.75rem;
  box-shadow: var(--shadow-1);
  transition: var(--transition);
  text-transform: capitalize;
  display: inline-block;
}
.btn:hover {
  background: var(--primary-700);
  box-shadow: var(--shadow-3);
}
.btn-hipster {
  color: var(--primary-500);
  background: var(--primary-200);
}
.btn-hipster:hover {
  color: var(--primary-200);
  background: var(--primary-700);
}
.btn-block {
  width: 100%;
}
.hero-btn {
  font-size: 1.25rem;
  padding: 0.5rem 1.25rem;
  background: #676868;
  margin-top: 20pX;
}
/* alerts */
.alert {
  padding: 0.375rem 0.75rem;
  margin: 0 auto;
  border-color: transparent;
  border-radius: var(--borderRadius);
  width: var(--fluid-width);
  max-width: var(--fixed-width);
  text-align: center;
  text-transform: capitalize;
}

.alert-danger {
  color: var(--red-dark);
  background: var(--red-light);
}
.alert-success {
  color: var(--green-dark);
  background: var(--green-light);
}
/* form */

.form,.comments-wrapper {
  width: var(--fluid-width);
  max-width: var(--fixed-width);
  background: var(--white);
  border-radius: var(--borderRadius);
  box-shadow: var(--shadow-2);
  padding: 2rem 2.5rem;
  margin: 3rem auto;
}
.comments-wrapper {
  width: 100%;
  max-width: none;
}
.form-label {
  display: block;
  font-size: var(--smallText);
  margin-bottom: 0.5rem;
  text-transform: capitalize;
  letter-spacing: var(--letterSpacing);
}
.form-input,
.form-textarea,
.form-select {
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-radius: var(--borderRadius);
  background: var(--backgroundColor);
  border: 1px solid var(--grey-200);
}
.form-textarea {
  overflow-y: hidden;
}

.form-row {
  margin-bottom: 1rem;
}

.form-textarea {
  height: 7rem;
}
::placeholder {
  font-family: inherit;
  /* color: var(--grey-400) !important; */
  letter-spacing: var(--letterSpacing);
}
.form-alert {
  color: var(--red-dark);
  letter-spacing: var(--letterSpacing);
  text-transform: capitalize;
}
/* alert */

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  width: 6rem;
  height: 6rem;
  border: 5px solid var(--grey-400);
  border-radius: 50%;
  border-top-color: var(--primary-500);
  animation: spinner 0.6s linear infinite;
  margin: 0 auto;
}
.loading {
  margin: 0 auto;
}
/* title */

.title {
  text-align: center;
}

.title-underline {
  background: var(--primary-500);
  width: 7rem;
  height: 0.25rem;
  margin: 0 auto;
  margin-top: -1rem;
}
.page {
  width: var(--fluid-width);
  max-width: var(--max-width);
  margin: 0 auto;
}
.full-page {
  min-height: 100vh;
}

.taskWrpper {
  background-image: url("task-list-template.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.logo {
  margin-top: 1rem;
  height: 5rem;
  width: auto;
}

.margin-bottom {
  margin-bottom:20px;
  display: block;
}

body .status.yelow {
  background-color: #F7941E;
  color: #ffff;
}

body .status.green {
  background-color: green;
  color: #ffff;
}

body .status.blue {
  background-color: #645CFF;
  color: #ffff;
}

body .status.red {
  background-color: red;
  color: #ffff;
}

.logo.left {
  max-width: 140px;
  height: auto;
}
#popup-root .popup-content {
  border-radius: 5px;
  padding: .5rem;
  -webkit-animation: .3s cubic-bezier(.38,.1,.36,.9) forwards a;
  width: auto;
  min-width: 300px;
}

.modal>.header {
    border-bottom: 1px solid gray;
    font-size: 18px;
    padding: 5px;
    text-align: center;
    width: 100%;
}

.modal>.actions, .modal>.content {
      padding: 10px 5px;
    width: 100%;
}

.modal>.close {
    background-color: #fff;
    border: 1px solid #cfcece;
    border-radius: 18px;
    cursor: pointer;
    display: block;
    font-size: 24px;
    padding: 2px 5px;
    position: absolute;
    right: -10px;
    top: -10px;
}

.close {
      background: 0 0;
    border: none;
    color: var(--ifm-color-black);
    float: right;
    font-size: 1.5rem;
    font-weight: var(--ifm-font-weight-bold);
    line-height: 1;
    opacity: .5;
    padding: 1rem;
    transition: opacity var(--ifm-transition-fast) var(--ifm-transition-timing-default);
}

.scrooling {
  background: #fff;
}

.btn-dashed {
  display: block;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='2' stroke-linecap='round'/%3e%3c/svg%3e");
  padding: 10px 30px;
  text-align: center;
  cursor: pointer;
}

.lacrima {
  background-color: #fff;
  border-top-left-radius: 140% 80px;
  border-top-right-radius: 3px ;
  border-bottom-left-radius: 100px 50px;
  border-bottom-right-radius: 110% 140%;
  border: 6px solid #27AAE1;
}

.form-wrapper {
   -webkit-transition: all 1s; 
  -moz-transition: all 1s; 
  -ms-transition:all 1s; 
  -o-transition: all 1s; 
  transition: all 1s;  
  max-height: 0;
  overflow: hidden;
  opacity: 0;
}
.form-wrapper.max {
  max-height: 400px;
  opacity: 1;
}

.btn.switch-form {
  min-width: 200px;
  position: relative;
}

.btn.switch-form > span > svg {
  position: absolute;
  right: 10px;
  font-size: 25px;
  top: 2px;
}
.top-action-section {
  position: relative;
  bottom: -4px;
}

.btn.task svg {
  font-size: 25px;
  position: relative;
  top: 5px;
  margin-top: -10px;
}
.btn.logout svg {
  font-size: 25px;
}
.sort-col {
  cursor: pointer;
}
.sort-col svg {
  font-size: 20px;
  position: relative;
  top: 5px;
}
.empty-serach {
  padding-top: 50px;
}
.default-value {
  color: darkgrey;
}
.not-default-value {
  color: #000;
}
.search-wrapper svg, .search-wrapper input::placeholder {
  color: darkgrey;
}

.auto-width {
  width: auto;
  margin-right: 10px;
}
.username-comment {
  font-size: 18px;
  text-transform: capitalize;
}
.little {
  font-size: 10px;
  color: darkgrey;
  display: block;
}
.comment {
  position: relative;
  border-bottom: 1px solid #ccc;
  padding-right: 60px;
  margin-bottom: 3px;
}
.delete-comment {
  position: absolute;
  right: -10px;
  top: 0;
}

#root .btn-right-bottom {
  margin-bottom: 10px;
}

.avatar-wrapper {
  display: block;
  width: auto;
}
.avatar-wrapper img {
  width: 40px;
  height: auto;
  display: inline-block;
} 
.avatar-image {
  width: 25px;
  height: auto;
  background: #ccc;
  border-radius: 50%;
}
.avatar-wrapper .form-label {
  display: inline-block;
} 
.check-wrapper {
  margin-top: 10px;
}
.check-wrapper .form-label {
  display: inline-block;
  margin-left: 5px;
}

.filesWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.filesWrapper > span {
  display: flex;
  flex-direction: row;
}
.images-actions {
  display: flex;
  flex-direction: column;
}

.description > span {
  display:  inline-block;
}